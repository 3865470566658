<form class="NetfirmLogin-form" onsubmit="return false">
	<netfirm-login-card
		[showPrimaryButton]="true"
		[showFirstLink]="showFirstLinkBind"
		firstLinktext="{{ 'Netfirm.CiamLogin.AccountUpgradeHelp' | translate }}"
		[showSecondaryButton]="showFormDivider"
		[switchButtons]="isMigrated"
		[showOptionDivider]="showFormDivider"
		[primaryButtonText]="(!isMigrated || !isRootPath ? 'Netfirm.CiamLogin.UpgradeAccount' : 'Netfirm.CiamLogin.UpgradeAccount') | translate"
		secondaryButtonText="{{'Netfirm.CiamLogin.SignInWithTR' | translate}}"
		(primaryButtonClick)="logInToNetfirm()"
		(secondaryButtonClick)="redirectToAuth0()"
		[showTextEnhancements]="true"
		[isRootPathC]="isRootPath"
	>
		<div slot="firm-info">
			<div class="NetfirmLogin-form--item" *ngIf="firmInfo.logoUrl">
				<img
					class="NetfirmLogin-form--logo"
					alt="Image depicting firms's logo"
					[src]="firmInfo.logoUrl"
					onerror="this.style.display='none'"
				/>
			</div>
			<h1 class="NetfirmLogin-form--item NetfirmLogin-brand--title">{{ firmInfo.text1 }}</h1>
			<p class="NetfirmLogin-form--item NetfirmLogin-brand--text">{{ firmInfo.text2 }}</p>
			<p class="NetfirmLogin-form--item NetfirmLogin-brand--text">{{ firmInfo.text3 }}</p>
		</div>
		<div *ngIf="!isMigrated || !isRootPath" slot="form-fields">
			<p *ngIf="isRootPath" class="NetfirmLogin-enhanced-title">
				<span [innerHTML]="enterCredentialsMessage"> </span>
				<saf-link
					target="_blank"
					id=""
					href="https://training.thomsonreuters.com/media/Migrating+your+NetClient+CS+or+Employee+Self-Service+account/1_ryir9kwa/324240752"
					>{{'Netfirm.CiamLogin.NeedHelp' | translate}}
					<saf-icon
						role="img"
						aria-label="Opens new tab"
						class="NetfirmLogin-Login-newTabIcon"
						size="16"
						icon-data="<svg alt='Opens new tab' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path d='M15.9615 0.30908C15.9109 0.18664 15.8134 0.08911 15.6909 0.03845C15.6298 0.01318 15.5651 0 15.5 0H8.5C8.22363 0 8 0.22363 8 0.5C8 0.77637 8.22363 1 8.5 1H14.293L7.14648 8.14648C6.95117 8.34179 6.95117 8.6582 7.14648 8.85351C7.24414 8.95117 7.37208 8.99999 7.50001 8.99999C7.62794 8.99999 7.75586 8.95116 7.85352 8.85351L15 1.70703V7.5C15 7.77637 15.2236 8 15.5 8C15.7764 8 16 7.77637 16 7.5V0.5C16 0.43494 15.9868 0.37018 15.9615 0.30908Z' fill='#005DA2'/>
								<path d='M12.5 9C12.2236 9 12 9.22363 12 9.5V14C12 14.5518 11.5518 15 11 15H2C1.44824 15 1 14.5518 1 14V5C1 4.44824 1.44824 4 2 4H6.5C6.77637 4 7 3.77637 7 3.5C7 3.22363 6.77637 3 6.5 3H2C0.89746 3 0 3.89746 0 5V14C0 15.1025 0.89746 16 2 16H11C12.1025 16 13 15.1025 13 14V9.5C13 9.22363 12.7764 9 12.5 9Z' fill='#005DA2'/>
								</svg>"
					></saf-icon>
				</saf-link>
			</p>
			<saf-form
				[attr.error-message]="safronFormFields.mainLoginName.errorMessage"
				[attr.validation-state]="safronFormFields.mainLoginName.validationState"
				element="input"
				type="text"
				name="userLogin"
				maxLength="64"
				elem-id="userLogin"
				label="{{ 'Netfirm.CiamLogin.LoginField' | translate }}"
				(keyup)="existingFirmEnter($event)"
				data-qe-id="text-userLogin"
			></saf-form>
			<saf-form
				[attr.error-message]="safronFormFields.mainPassword.errorMessage"
				[attr.validation-state]="safronFormFields.mainPassword.validationState"
				element="input"
				type="password"
				name="userPassword"
				maxLength="64"
				elem-id="userPassword"
				label="{{ 'Netfirm.CiamLogin.PasswordField' | translate }}"
				(keyup)="existingFirmEnter($event)"
				data-qe-id="text-Password"
			></saf-form>
			<div class="NetfirmLogin-form--forgotPassword">
				<saf-link
					(click)="openModal('forgotPassword')"
					id="linkForgotPassword"
					role="link"
					tabindex="0"
					(keyup)="forgotPasswordEnter($event)"
					><span class="NetfirmLogin-form--questionIcon"
						><saf-icon
							size="16"
							icon-data="<svg alt=' ' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<g clip-path='url(#clip0_102_9)'>
										<path fill-rule='evenodd' clip-rule='evenodd' d='M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z' fill='#005DA2'/>
										<path d='M8.003 13C8.55528 13 9.003 12.5523 9.003 12C9.003 11.4477 8.55528 11 8.003 11C7.45072 11 7.003 11.4477 7.003 12C7.003 12.5523 7.45072 13 8.003 13Z' fill='#005DA2'/>
										<path fill-rule='evenodd' clip-rule='evenodd' d='M8.35819 3.51353C7.79511 3.44684 7.19015 3.62769 6.83498 3.9482C6.62997 4.1332 6.3138 4.11699 6.1288 3.91198C5.9438 3.70697 5.96001 3.3908 6.16502 3.2058C6.76285 2.66631 7.66688 2.42466 8.47581 2.52047C9.30117 2.61823 10.1338 3.08893 10.4051 4.07956C10.5684 4.67214 10.5067 5.15927 10.2947 5.58993C10.1164 5.95202 9.83485 6.25905 9.59257 6.52324C9.57124 6.54649 9.55022 6.56941 9.52961 6.59201C9.25808 6.8896 9.00863 7.17625 8.81999 7.54443C8.63542 7.90468 8.5 8.36264 8.5 9C8.5 9.27614 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.27614 7.5 9C7.5 8.20836 7.6712 7.59357 7.93001 7.08844C8.18474 6.59125 8.51542 6.2199 8.79089 5.91799C8.80188 5.90595 8.81273 5.89406 8.82345 5.88232C9.09776 5.58184 9.28505 5.37668 9.39755 5.1482C9.49632 4.94759 9.54252 4.71312 9.44099 4.34492C9.30716 3.85586 8.90469 3.57826 8.35819 3.51353Z' fill='#005DA2'/>
										</g>
										<defs>
										<clipPath id='clip0_102_9'>
										<rect width='16' height='16' fill='white'/>
										</clipPath>
										</defs>
										</svg>
								"
						></saf-icon
					></span>
					<span class="NetfirmLogin-form--forgotPasswordText"
						>{{ 'Netfirm.CiamLogin.ForgotPassword' | translate }}</span
					></saf-link
				>
			</div>
		</div>
	</netfirm-login-card>
</form>

<netfirm-login-modal
	[open]="openForgotPasswordModal"
	[showPrimaryButton]="true"
	[showSecondaryButton]="true"
	primaryButtonText="{{'Netfirm.CiamLogin.GetTemporaryPassword' | translate}}"
	secondaryButtonText="{{'Netfirm.CiamLogin.Cancel' | translate}}"
	(primaryButtonClick)="resetPasswordViaEmail()"
	(secondaryButtonClick)="closeModal(ModalIdentifier.ForgotPassword)"
	(closeModal)="closeModal(ModalIdentifier.ForgotPassword)"
>
	<form slot="body" class="NetfirmLogin-form" onsubmit="return false">
		<h1 class="NetfirmLogin-form--item NetfirmLogin-newPassword--list">
			{{ 'Netfirm.CiamLogin.ForgotPassword' | translate }}
		</h1>
		<ng-container *ngIf="displayInlineError">
			<netfirm-login-banner
				[dismissable]="false"
				[isToast]="false"
				[type]="AlertType.ERROR"
				[message]="inlineErrorMessage"
			></netfirm-login-banner>
		</ng-container>
		<p class="NetfirmLogin-paragraph">{{ 'Netfirm.CiamLogin.WeWillSendTemporaryPassword' | translate }}</p>
		<saf-form
			[attr.error-message]="safronFormFields.loginReset.errorMessage"
			[attr.validation-state]="safronFormFields.loginReset.validationState"
			type="text"
			label="{{ 'Netfirm.CiamLogin.LoginField' | translate }}"
			id="saf-loginReset"
			maxLength="64"
			(keyup)="resetPasswordViaEmailEnter($event)"
			elem-id="loginReset"
			data-qe-id="text-Login-Reset"
		></saf-form>
		<saf-form
			[attr.error-message]="safronFormFields.emailReset.errorMessage"
			[attr.validation-state]="safronFormFields.emailReset.validationState"
			id="saf-emailReset"
			type="email"
			maxLength="64"
			(keyup)="resetPasswordViaEmailEnter($event)"
			label="{{ 'Netfirm.CiamLogin.EmailAssociated' | translate}}"
			[attr.info-message]="'Netfirm.CiamLogin.ContactFirmForHelp' | translate"
			elem-id="emailReset"
			data-qe-id="text-email-Reset"
		></saf-form>
	</form>
</netfirm-login-modal>
<netfirm-login-modal
	[open]="openResetPasswordModal"
	[showPrimaryButton]="true"
	[showSecondaryButton]="true"
	primaryButtonText="{{'Netfirm.CiamLogin.Continue' | translate}}"
	secondaryButtonText="{{'Netfirm.CiamLogin.Cancel' | translate}}"
	(primaryButtonClick)="resetPassword()"
	(secondaryButtonClick)="closeModal(ModalIdentifier.ResetPassword)"
	(closeModal)="closeModal(ModalIdentifier.ResetPassword)"
>
	<form slot="body" class="NetfirmLogin-form" onsubmit="return false">
		<h1 class="NetfirmLogin-form--item NetfirmLogin-newPassword--list">
			{{ 'Netfirm.CiamLogin.ResetYourPassword' | translate }}
		</h1>
		<ng-container *ngIf="resetPasswordViaEmailError">
			<p class="NetfirmLogin-errorMessage">{{ resetPasswordViaEmailError }}</p>
		</ng-container>
		<p class="NetfirmLogin-listTitle">your new password must:</p>
		<ul class="NetfirmLogin-newPassword--list">
			<li>{{'Netfirm.CiamLogin.PasswordMinCharacters' | translate}}</li>
			<li>
				{{'Netfirm.CiamLogin.PasswordContainFollowing' | translate}}
				<ul class="NetfirmLogin-newPassword--requirement NetfirmLogin-listPadding">
					<li
						data-qe-id="text-An-uppercase-letter"
						[ngClass]="{
							'NetfirmLogin-newPassword--requirementValid': passwordRequirements.uppercaseLetter,
							'NetfirmLogin-newPassword--requirementNonValid': !passwordRequirements.uppercaseLetter
						}"
					>
						{{'Netfirm.CiamLogin.PasswordUppercase' | translate}}
					</li>
					<li
						data-qe-id="text-An-lowercase-letter"
						[ngClass]="{
							'NetfirmLogin-newPassword--requirementValid': passwordRequirements.lowercaseLetter,
							'NetfirmLogin-newPassword--requirementNonValid': !passwordRequirements.lowercaseLetter
						}"
					>
						{{'Netfirm.CiamLogin.PasswordLowercase' | translate}}
					</li>
					<li
						data-qe-id="text-A-Number"
						[ngClass]="{
							'NetfirmLogin-newPassword--requirementValid': passwordRequirements.numericValue,
							'NetfirmLogin-newPassword--requirementNonValid': !passwordRequirements.numericValue
						}"
					>
						{{'Netfirm.CiamLogin.PasswordNumber' | translate}}
					</li>
					<li
						data-qe-id="text-A-special-character"
						[ngClass]="{
							'NetfirmLogin-newPassword--requirementValid': passwordRequirements.specialCharacter,
							'NetfirmLogin-newPassword--requirementNonValid': !passwordRequirements.specialCharacter
						}"
					>
						{{'Netfirm.CiamLogin.SpecialCharacter' | translate}}
					</li>
				</ul>
			</li>
		</ul>
		<saf-form
			[attr.error-message]="safronFormFields.newPassword.errorMessage"
			[attr.validation-state]="safronFormFields.newPassword.validationState"
			id="saf-newPassword"
			label="{{'Netfirm.CiamLogin.NewPassword' | translate}}"
			type="password"
			maxLength="64"
			(keyup)="resetPasswordEnterEnter($event)"
			elem-id="newPassword"
			data-qe-id="text-update-newpassword"
		></saf-form>
		<saf-form
			label="{{'Netfirm.CiamLogin.ConfirmNewPassword' | translate}}"
			type="password"
			maxLength="64"
			id="saf-confirmNewPassword"
			(keyup)="resetPasswordEnterEnter($event)"
			elem-id="confirmNewPassword"
			data-qe-id="text-update-Confirmnewpassword"
		></saf-form>
	</form>
</netfirm-login-modal>
