import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DWRResponseType } from '../enums/dwr-response.enum';
import { NetfirmExceptions } from '../models/netfirm-login-constants.model';

@Injectable({
	providedIn: 'root',
})
export class DwrService {
	public constructor(private readonly httpClient: HttpClient) {}

	public emailNewPassword(login: string, email: string): Observable<DWRResponseType> {
		const method = 'emailNewPassword';
		const serviceName = 'WSD_securityQuestionService';
		const headers = new HttpHeaders({
			'Content-Type': 'text/plain',
		});

		const data = [
			'callCount=1',
			'page=/nextgen/packaged/pwd-reset/1.1.0/',
			`httpSessionId=`,
			`scriptSessionId=`,
			`c0-scriptName=${serviceName}`,
			`c0-methodName=${method}`,
			`c0-id=0`,
			`c0-param0=string:${login}`,
			`c0-param1=string:${email.replace('@', '%40').replace('+', '%2b')}`,
			`c0-param2=boolean:false`,
			`c0-param3=boolean:false`,
			`batchId=0`,
		].join('\n');

		return this.httpClient
			.post(`/dwr/call/plaincall/${serviceName}.${method}.dwr`, data, { headers, responseType: 'text' })
			.pipe(
				map(response => {
					return this.parseDwrResponse(response);
				}),
			);
	}

	public parseDwrResponse(dwrResponse: string): DWRResponseType {
		// _remoteHandleCallback is included in the response when the request was successful
		if (dwrResponse.includes('dwr.engine._remoteHandleCallback')) {
			return DWRResponseType.Success;
		}

		if (dwrResponse.includes('dwr.engine._remoteHandleException')) {
			const exceptionObjectRegex = /\{.*\}/s;
			const regexResult = exceptionObjectRegex.exec(dwrResponse);
			if (regexResult) {
				const exceptionObjectString = regexResult[0];
				const dwrErrorObject = JSON.parse(exceptionObjectString.replace(/(\w+):/g, '"$1":'));
				if (dwrErrorObject.javaClassName === NetfirmExceptions.InvalidLoginEmailCombinationException) {
					return DWRResponseType.BadCombination;
				}

				if (dwrErrorObject.javaClassName === NetfirmExceptions.BlockedLogin) {
					return DWRResponseType.TooManyAttempts;
				}
			}
		}

		return DWRResponseType.ParseError;
	}
}
