import { Component, AfterViewChecked, AfterViewInit } from '@angular/core';
import { NetfirmLoginService } from '../../services/netfirm-login.service';
import { Router } from '@angular/router';
import { LoginResponse } from '../../models/login.model';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
@Component({
	selector: 'netfirm-login-unknown-login-failure',
	templateUrl: './netfirm-unknown-login-failure.component.html',
	styleUrls: ['./netfirm-unknown-login-failure.component.scss'],
})
export class NetfirmUnknownLoginFailureComponent implements AfterViewChecked, AfterViewInit {
	public urlRedirect!: string;
	public subscription = new Subscription();

	public constructor(
		private readonly router: Router,
		private readonly loginService: NetfirmLoginService,
		private readonly title: Title,
	) {
		this.title.setTitle('Login error');
	}

	public ngAfterViewChecked(): void {
		document.querySelector('.NetfirmLogin-Login-seconLink')?.setAttribute('style', 'text-align:left;padding:0px;');
	}

	public ngAfterViewInit(): void {
		// Clases to modify the safron controls just inside of unknow-login-failure-component
		document.querySelector('saf-card')?.firstElementChild?.parentElement?.setAttribute('class', 'Netfirm-MoreWith');
		document.querySelector('saf-button')?.setAttribute('class', 'Netfirm-buttonReturn');
	}

	public async goToLogin(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.router
				.navigate([])
				.then(() => {
					window.open('#/', '_blank');
					resolve();
				})
				.catch(error => {
					console.error('Error:', error);
					reject(error);
				});
		});
	}

	public logout(): void {
		this.subscription.add(
			this.loginService.logoutToNetfirm().subscribe((firmInformation: LoginResponse) => {
				this.urlRedirect = firmInformation.redirectTo ?? '';
				if (this.urlRedirect) {
					window.open(this.urlRedirect);
				}
			}),
		);
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
