import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { FirmInformation } from '../models/firm-information.model';
import { FirmAccessStatus, LoginResponse } from '../models/login.model';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class NetfirmLoginService {
	public httpClient!: HttpClient;
	public constructor(private readonly http: HttpClient) {
		this.httpClient = http;
	}

	public getFirmInformation(firmId: string): Observable<FirmInformation> {
		const firmInformationUrl = `/rest/appearance/firm/${firmId}/splash`;
		return this.http.get<FirmInformation>(firmInformationUrl);
	}

	public loginToNetfirm(user: string, password: string): Observable<LoginResponse> {
		const loginUrl = '/nextgen/login';
		const formData = new HttpParams().set('loginUsername', user).set('loginPassword', password);
		return this.http.post<LoginResponse>(loginUrl, formData).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.message.includes('failure during parsing')) {
					return of({
						tokenValue: '',
						firmID: '',
						redirectTo: err?.error?.text?.split(' ')[1],
						accountType: '',
					});
				}

				return throwError(err);
			}),
		);
	}

	public resetPassword(login: string, oldPassword: string, newPassword: string): Observable<{ message: string }> {
		const passwordResetUrl = '/rest/passwords';

		const formData = new HttpParams().set('login', login).set('oldPassword', oldPassword).set('newPassword', newPassword);

		return this.http.put<{ message: string }>(passwordResetUrl, formData);
	}

	public checkMaintenanceMode(firmId: string): Observable<FirmAccessStatus> {
		let maintenanceModeUrl;
		if (firmId) {
			maintenanceModeUrl = `/rest/firms/${firmId}/access-status`;
		} else {
			maintenanceModeUrl = `/rest/firms/access-status`;
		}

		return this.http.get<FirmAccessStatus>(maintenanceModeUrl);
	}

	public logoutToNetfirm(): Observable<LoginResponse> {
		const logoutUrl = '/nextgen/logout';
		return this.http.post<LoginResponse>(logoutUrl, null).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err.message.includes('failure during parsing')) {
					return of({
						tokenValue: '',
						firmID: '',
						redirectTo: err?.error?.text,
						accountType: '',
					});
				}

				return throwError(err);
			}),
		);
	}
}
