import { Component } from '@angular/core';
import { NetfirmLoginConstants } from './models/netfirm-login-constants.model';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
@Component({
	selector: 'netfirm-login-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	public title = 'NetfirmLogin';

	public constructor(private readonly translate: TranslateService, private readonly cookieService: CookieService) {
		const url = window.location.href;
		const hash = window.location.hash;
		const isInternal = NetfirmLoginConstants.internalRoutes
			.map(routeURL => hash.includes(routeURL))
			.reduce((prev, next) => prev || next);
		if (hash.length > 2 && !isInternal) {
			document.cookie = `dashboardDeepLink=${url};expires=Session;path=/`;
		}

		const languageCode = this.cookieService.get('NFlanguageCode');
		const cookieLocale = this.cookieService.get('locale');
		const browserLang = this.translate.getBrowserLang();
		let locale;
		if (languageCode) {
			locale = languageCode;
		} else if (cookieLocale) {
			locale = cookieLocale;
		} else {
			switch (browserLang) {
				case 'en': {
					const origin = window.location.origin.split('.');
					const tld = origin[url.length - 1];
					locale = tld === 'ca' ? 'en-CA' : 'en-US';
					break;
				}

				case 'fr':
					locale = 'fr-CA';
					break;
				default:
					locale = 'en-US';
			}
		}

		this.translate.setDefaultLang('en-US');
		this.translate.use(locale);
	}
}
