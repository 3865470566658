import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ModalSize } from '../../../enums/modal-size.enum';

@Component({
	selector: 'netfirm-login-modal',
	templateUrl: './netfirm-modal.component.html',
	styleUrls: ['./netfirm-modal.component.scss'],
})
export class NetfirmModalComponent implements AfterViewInit {
	@ViewChild('netfirmModalRef', { static: false }) public netfirmModalRef!: ElementRef;
	@Input() public open = false;
	@Input() public showPrimaryButton = true;
	@Input() public showSecondaryButton = true;
	@Input() public primaryButtonText!: string;
	@Input() public secondaryButtonText!: string;
	@Input() public size: ModalSize = ModalSize.small;
	@Output() public primaryButtonClick = new EventEmitter<void>();
	@Output() public secondaryButtonClick = new EventEmitter<void>();
	@Output() public closeModal = new EventEmitter<void>();

	public ngAfterViewInit(): void {
		this.closeModalEvent = this.closeModalEvent.bind(this);
		this.netfirmModalRef?.nativeElement?.addEventListener('closemodal', this.closeModalEvent);
	}

	public closeModalEvent(): void {
		this.closeModal.emit();
	}
}
