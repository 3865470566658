import { Component, Input, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { NetfirmLoginConstants } from '../../../models/netfirm-login-constants.model';
import { SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'netfirm-login-card',
	templateUrl: './netfirm-card.component.html',
	styleUrls: ['./netfirm-card.component.scss'],
})
export class NetfirmCardComponent implements OnInit, AfterViewInit {
	public viewSystemStatusLink = NetfirmLoginConstants.viewSystemStatusLink;
	public firmId!: string;
	public primaryVariant = 'primary';
	public secondaryVariant = 'secondary';
	@Input() public showPrimaryButton = true;
	@Input() public showSecondaryButton = true;
	@Input() public showFirstLink = true;
	@Input() public showSecondLink = true;
	@Input() public showOptionDivider = true;
	@Input() public labeltooltipText = 'label tooltip text';
	@Input() public showTooltip = false;
	@Input() public primaryButtonText = 'button';
	@Input() public secondaryButtonText = 'button';
	@Input() public switchButtons = false;
	@Input() public firstLinktext = 'Not sure what to do?';
	@Input() public firstLinkURL = NetfirmLoginConstants.notSureWhatToDoLink;
	@Input() public firstLinkTooltipText: SafeHtml = '';
	@Input() public disabledPrimaryButton = false;
	@Input() public showTextEnhancements = false;
	@Input() public isRootPathC = true;
	@Output() public primaryButtonClick = new EventEmitter<void>();
	@Output() public secondaryButtonClick = new EventEmitter<void>();

	public ngOnInit(): void {
		if (this.switchButtons && this.showPrimaryButton && this.showSecondaryButton) {
			this.primaryVariant = 'secondary';
			this.secondaryVariant = 'primary';
		}
	}

	public ngAfterViewInit(): void {
		this.validateEnableDisableButton = this.validateEnableDisableButton.bind(this);
		document.getElementById('noInfoLogin-safkeydown')?.addEventListener('safInput', this.validateEnableDisableButton);
	}

	// Method to manage the keyup event in the noinfoLogin texbox to disable or enable the button.
	public validateEnableDisableButton(event: Event): void {
		const preuserName = (event as any).detail.target.value;
		if (preuserName.length > 0) {
			this.disabledPrimaryButton = false;
		} else {
			this.disabledPrimaryButton = true;
		}
	}
}
