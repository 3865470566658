import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirmAccessStatus } from '../../models/login.model';
import { NetfirmLoginService } from '../../services/netfirm-login.service';

@Component({
	selector: 'netfirm-login-maintenance-mode',
	templateUrl: './netfirm-maintenance-mode.component.html',
	styleUrls: ['./netfirm-maintenance-mode.component.scss'],
})
export class NetfirmMaintenanceModeComponent implements OnInit, OnDestroy {
	public subscription = new Subscription();

	public constructor(
		private readonly router: Router,
		private readonly loginService: NetfirmLoginService,
		private readonly cookieService: CookieService,
	) {}

	public goToLogin(): void {
		// Return to the login
		void this.router.navigate(['/']);
	}

	public ngOnInit(): void {
		const cookieFirmId = this.cookieService.get('firmID');
		const queryParams = new URLSearchParams(window.location.search);
		const urlFirmId = queryParams.get('firm') ?? '';
		this.subscription.add(
			this.loginService
				.checkMaintenanceMode(cookieFirmId || urlFirmId)
				.pipe(
					map((maintenanceAccess: FirmAccessStatus) => {
						if (maintenanceAccess.maintenanceMode) {
							if (maintenanceAccess.maintenanceFirm === undefined || !maintenanceAccess.maintenanceFirm) {
								return false;
							}

							void this.router.navigate(['/']);
							return true;
						}

						void this.router.navigate(['/']);
						return true;
					}),
				)
				.subscribe(),
		);
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
