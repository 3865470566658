<netfirm-login-card
	[showPrimaryButton]="true"
	[showFirstLink]="false"
	[showSecondaryButton]="false"
	[showOptionDivider]="false"
	primaryButtonText="Refresh page"
	(primaryButtonClick)="goToLogin()"
>
	<div slot="body">
		<div style="text-align: center">
			<saf-svg semantic="" size="64px" color="#404040ff">
				<svg width="60" height="59" viewBox="0 0 60 59" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="Group">
						<g id="Group_2">
							<g id="Group_3">
								<path
									id="Shape"
									d="M37.3016 26.3L54.0016 43C57.3016 46.3 57.3016 51.7 54.0016 55V55C50.7016 58.3 45.3016 58.3 42.0016 55L26.1016 39.1"
									stroke="#FA6400"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									id="Shape_2"
									d="M26.4 23.4L18 15V10.5L9.5 2L1 10.5L9.5 19H14L22.2 27.2"
									stroke="#FA6400"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									id="Shape_3"
									d="M38 39L46 47"
									stroke="#FA6400"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									id="Shape_4"
									d="M41.9 21.2L12 55L2 45L35.8 15.1"
									stroke="#404040"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									id="Shape_5"
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M55.4 18.6L53.3 20.7L49.3 16.7L51.4 14.6L41.1 4.3C36.6 -0.1 29.4 -0.1 25 4.3L43.3 22.6L45.7 20.2L49.7 24.2L47.3 26.6L50.8 30.1L58.8 22.1L55.4 18.6Z"
									stroke="#404040"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</g>
						</g>
					</g>
				</svg>
			</saf-svg>
		</div>
		<div class="NetfirmLogin-form--container">
			<h1 class="NetfirmLogin-Title">We're offline - please try again later</h1>
		</div>
	</div>
</netfirm-login-card>
