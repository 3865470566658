<netfirm-login-card
	[showPrimaryButton]="true"
	[showFirstLink]="false"
	[showSecondaryButton]="false"
	[showOptionDivider]="false"
	primaryButtonText="Sign back in"
	(primaryButtonClick)="goToLogin()"
>
	<div slot="body" class="NetfirmLogin-SignBackIn--container">
		<h1 class="Netfirm-H1Title">You've been signed out</h1>
		<p class="NetfirmLogin-paragraph"></p>
	</div>
</netfirm-login-card>
