<saf-modal
	#netfirmModalRef
	[open]="open"
	closeOnBackgroundClick="true"
	[class]="size"
	[attr.aria-hidden]="!open"
	closeIconQeId="close-icon-button"
	id="prueba"
>
	<div slot="header">
		<ng-content select="[slot=header]"></ng-content>
	</div>
	<div slot="content">
		<ng-content select="[slot=body]"></ng-content>
	</div>

	<div slot="footer" class="NetfirmModal-footer">
		<saf-button
			variant="secondary"
			class="NetfirmModal-cancel"
			data-qe-id="modal-cancel-button"
			*ngIf="showSecondaryButton"
			(click)="secondaryButtonClick.emit()"
		>
			{{ secondaryButtonText }}
		</saf-button>
		<saf-button
			variant="primary"
			data-qe-id="modal-continue-button"
			*ngIf="showPrimaryButton"
			(click)="primaryButtonClick.emit()"
		>
			{{ primaryButtonText }}
		</saf-button>
	</div>
</saf-modal>
