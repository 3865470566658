import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NetfirmLoginConstants } from '../../models/netfirm-login-constants.model';

@Component({
	selector: 'netfirm-login-unable-login',
	templateUrl: './netfirm-unable-login.component.html',
	styleUrls: ['./netfirm-unable-login.component.scss'],
})
export class NetfirmUnableLoginComponent implements OnInit {
	public shiftTimeText!: string;
	public shiftTimeValid = true;
	public typeUnable!: string;

	public constructor(private readonly route: ActivatedRoute) {}

	public ngOnInit(): void {
		this.typeUnable = this.route.snapshot.params.parameter;

		if (this.typeUnable === 'shift') {
			this.shiftTimeValid = false;
			this.shiftTimeText = NetfirmLoginConstants.textshiftTime;
		}
	}
}
